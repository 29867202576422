import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { useApi } from '~/siteApi'
import { useStaticQuery, graphql } from 'gatsby'
import { useMediaQuery } from 'react-responsive'
import { PageProps } from 'gatsby'
import {
  Head,
  Hero,
  Button,
  Numbers,
  Testimonials,
  Certifications,
  Footer,
} from '~/components'
import cn from 'classnames'
import * as st from '~/assets/styl/About.module.styl'
import * as st1 from '~/assets/styl/Habita.module.styl'
import * as st2 from '~/assets/styl/NotFound.module.styl'

import Banner from '~/assets/img/Sobre-nos.jpg'
import Goal from '~/assets/svg/Objetivos.svg'
import Talent from '~/assets/svg/Talentos.svg'
import Rating from '~/assets/svg/Impactos.svg'
import Quality from '~/assets/svg/Qualidade.svg'

const Icon = styled.div<{ src: string }>`
  ${(props) =>
    props.src &&
    css`
      background-image: url(${props.src});
    `}
`

const Bar = styled.li<{ value: number }>`
  ${(props) =>
    css`
      height: ${props.value * 100}%;
    `}
`

interface Project {
  value: string
  year: string
}

const About = ({ location }: PageProps) => {
  const {
    result: _projects,
  }: {
    result: Array<Project>
  } = useApi(
    {
      result: useStaticQuery(graphql`
        query {
          allProjects {
            nodes {
              value
              year
            }
          }
        }
      `).allProjects.nodes,
    },
    'timelines'
  )
  const currentYear = new Date().getFullYear()
  const maxArea = _projects.reduce((a, b) => {
    return Math.max(
      a,
      parseFloat(b.value.replace(',', '.').replace(/[^0-9.]/g, ''))
    )
  }, -Infinity)
  const firstNotDone = _projects.findIndex(
    ({ year }) => parseInt(year.match(/\d+(?=\D*$)/)[0]) >= currentYear
  )
  const projects = _projects.map((p, i) => ({
    ...p,
    firstNotDone: i === firstNotDone,
    notDone: i >= firstNotDone,
    relValue:
      parseFloat(p.value.replace(',', '.').replace(/[^0-9.]/g, '')) / maxArea,
  }))

  const mobile = !useMediaQuery({
    query: '(min-width: 951px)',
  })

  const [value, setValue] = useState(0)
  const values = [
    {
      name: 'Amor',
      description: 'Demonstração de zelo e dedicação ao trabalho e as pessoas.',
    },
    {
      name: 'Confiança',
      description:
        'Fazer o que deve ser feito, visando às expectativas e especificações dos clientes internos e externos.',
    },
    {
      name: 'Rentabilidade',
      description:
        'Gerar recursos financeiros que garantam a sustentabilidade do negócio.',
    },
    {
      name: 'Excelência',
      description:
        'Fazer o melhor sempre, visando à superação de metas e expectativas.',
    },
    {
      name: 'Disciplina',
      description: 'Ter proatividade, continuidade e foco.',
    },
  ]

  return (
    <>
      <Head
        location={location}
        title={'Sobre nós - ' + process.env.GATSBY_SITE_NAME}
      />

      <Hero
        title="Sobre nós"
        description={
          'Sensibilidade que constrói histórias verdadeiras, com a dedicação da nossa família para a sua!'
        }
        image={Banner}
      />

      <section className={st1.core}>
        <div className={st1.container}>
          <div className={st1.text}>
            <h2>Quem Somos</h2>
            <p>
              A Holder Construtora iniciou suas atividades em janeiro de 2009,
              na cidade de Chapecó-SC.
              <br />
              Desde então, a Holder Construtora tem se dedicado a realizar os
              sonhos de famílias do oeste de Santa Catarina.
            </p>
          </div>
          <div className={cn(st1.image, st.whoWeAre)}></div>
        </div>
      </section>

      <section className={st1.next}>
        <div className={st1.nextContainer}>
          <h2>
            Planejar,
            <br />
            desenvolver
            <br />e executar.
          </h2>
          <p className={st.plan}>
            Atuamos no planejamento, desenvolvimento e execução de projetos
            imobiliários diferenciados, de médio e alto padrão.
            <br />
            <br />
            Sensibilidade que constrói histórias verdadeiras, com a dedicação da
            nossa família para a sua!
          </p>
          <Button href="/empreendimentos/" className={st2.btn}>
            {mobile ? 'Empreendimentos' : 'Nossos empreendimentos'}
          </Button>
        </div>
      </section>

      <section className={st.services}>
        <div className={st.heading}>
          <h2>Serviços executados pela Holder</h2>
          <p>
            Além de apartamentos, edifícios e empreendimentos, a Holder também
            projeta e executa casas de alto padrão, para quem não se adapta em
            viver dentro de um apartamento.
            <br />
            <br />
            Todos os nossos serviços visam entregar o melhor produto para nossos
            clientes, seja para viver ou investir. Sensibilidade, criatividade e
            profissionalismo para entregar o melhor para quem nos procura.
          </p>
        </div>
        <div>
          <div>
            <h3>Edifícios de alto padrão</h3>
          </div>
          <div>
            <h3>Casas de alto padrão</h3>
          </div>
        </div>
      </section>

      <Numbers />

      {/* <section className={st.constructions}>
        <h2>Construções por m²</h2>
        <ul className={st.chart}>
          {projects.map(
            ({ year, value, relValue, notDone, firstNotDone }, key) => (
              <Bar
                value={relValue}
                className={!notDone ? st.done : null}
                key={key}
              >
                <h3>{year}</h3>
                <p>{value}</p>
                {firstNotDone ? (
                  <p className={st.legend}>Projetos em carteira</p>
                ) : key === 0 ? (
                  <p className={st.legend}>Projetos executados</p>
                ) : (
                  <></>
                )}
              </Bar>
            )
          )}
        </ul>
      </section> */}

      <section className={st.why}>
        <div className={st.container}>
          <div className={st.heading}>
            <h2>Por que chegamos tão longe?</h2>
            <p>
              Porque valorizamos o capital humano, na base da união, seguindo
              uma cultura forte e planejada!
            </p>
          </div>
          <div className={st.content}>
            <div>
              <Icon src={Goal} className={st.icon} />
              <h3>Temos objetivos concretos</h3>
              <p>
                Em que formalizamos nossos valores, concentrando esforços e
                energias na direção certa.
              </p>
            </div>
            <div>
              <Icon src={Talent} className={st.icon} />
              <h3>Retemos talentos</h3>
              <p>Valorizamos o capital humano e motivamos seus propósitos.</p>
            </div>
            <div>
              <Icon src={Rating} className={st.icon} />
              <h3>Impactamos positivamente</h3>
              <p>
                Somos comprometidos com a empresa, para deixar assim um ótimo
                legado.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className={st.values}>
        <div className={st.container}>
          <div>
            <h2>Missão</h2>
            <p>
              “Impactar os clientes com sensibilidade e criatividade,
              proporcionando conquistas, bem estar e solidez de negócios.”
            </p>
          </div>
          <div>
            <h2>Visão</h2>
            <p>
              “Entregar a melhor opção de lar e investimento imobiliário de
              Chapecó”.
            </p>
          </div>
          <div>
            <h2>Valores</h2>
            <div className={st.content}>
              {values.map(({ name, description }, index) => (
                <div className={index === value ? st.active : null} key={index}>
                  <h3>
                    {index + 1} / {name}
                  </h3>
                  <p>{description}</p>
                </div>
              ))}
            </div>
            <ul className={st.tabs}>
              {values.map((_, index) => (
                <li
                  onClick={() => setValue(index)}
                  className={index === value ? st.active : null}
                  key={index}
                >
                  {index + 1}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>

      <section className={st.quality}>
        <Icon src={Quality} className={st.icon} />
        <h2>Política de Qualidade</h2>
        <p>
          &ldquo;Entregar resultados, priorizando a qualidade do que fazemos,
          buscando melhoria contínua e comprometendo-nos com a satisfação dos
          nossos clientes, respeitando os requisitos legais.&ldquo;
        </p>
      </section>

      <Testimonials />

      <Certifications />

      <Footer />
    </>
  )
}

export default About
